import React from "react"
import Img from "gatsby-image"
import { graphql, Link, useStaticQuery } from "gatsby"

const Testimonial = ({ testimonialData }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { draft: { eq: false }, type: { eq: "case-study" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              slug
              type
              title
              thumbnail {
                childImageSharp {
                  fluid(maxHeight: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const resourceNode = data.allMarkdownRemark.edges.find(edge => {
    return (
      edge.node.frontmatter.type === "case-study" &&
      edge.node.frontmatter.slug === testimonialData.resource
    )
  })
  if (!testimonialData || !resourceNode) return null

  return (
    <div className="container testimonial-card grid services__testimonial-card">
      <div className="testimonial-card__result">
        <Img
          fluid={resourceNode.node.frontmatter.thumbnail.childImageSharp.fluid}
          objectFit="cover"
          className="testimonial-card__image"
          alt="hero warehouse"
        />
        <h4>{resourceNode.node.frontmatter.title}</h4>
        <Link
          to={`/case-studies/${testimonialData.resource}`}
          className="button button--blue-light"
        >
          Read Case Study
        </Link>
      </div>

      <div className="testimonial-card__quote">
        <h3>{testimonialData.quote}</h3>
        <div className="testimonial-card__author">
          {/* <img
src="https://via.placeholder.com/150"
alt="placeholder"
className="testimonial-card__avatar"
/> */}
          <div>
            <h4 className="h4--bold">{testimonialData.quote_author}</h4>
            <p>{testimonialData.quote_author_position}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Testimonial
