import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import markdownit from "markdown-it"
import React, { useRef, useState } from "react"
import ConsulationCta from "../components/ConsultationCta"
import ContactForm from "../components/ContactForm"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SoftwareCta from "../components/SoftwareCta"
import ChevronDownIcon from "../images/icons/chevron-down.inline.svg"
import Testimonial from "../components/Testimonial"

const md = markdownit({ html: true })

const Services = ({ data }) => {
  const ecommerceRef = useRef(null)
  const operationsRef = useRef(null)
  const sustainabilityRef = useRef(null)

  const sectionRefs = {}

  const scrollToRef = title => {
    sectionRefs[title].scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout>
      <SEO title="Services" />

      <div className="header services__header">
        <div className="container grid">
          <div className="header-content header-content--wide">
            <h1>
              Services tailored to your specific needs, from staff that’s ready
              to help
            </h1>
          </div>
        </div>

        <div className="container header__buttons">
          {data.allServicesYaml.edges.map(({ node }) => {
            return (
              <button
                key={node.title}
                onClick={() => scrollToRef(node.title)}
                className="header__button button button--beige button--arrow-down"
              >
                {node.title}
              </button>
            )
          })}
        </div>
      </div>

      {data.allServicesYaml.edges.map(({ node }) => {
        return (
          <React.Fragment key={node.title}>
            <section
              id={node.title}
              className="container services__container"
              ref={ecommerceRef}
              ref={el => (sectionRefs[node.title] = el)}
            >
              <div className="service">
                <div className="service-content__container">
                  <div className="service-indicator">
                    <img
                      src={node.icon.publicURL}
                      alt={`Icon for ${node.title} service offering`}
                    />
                    <div className="service-indicator__line service-indicator__line--services">
                      <div className="service-indicator__line--vertical" />
                      <div className="service-indicator__line--corner" />
                    </div>
                  </div>

                  <div className="service-content service-content--line">
                    <h2 className="service-content__title h2 h2--bold">
                      {node.title}
                    </h2>

                    <p>{node.description}</p>
                  </div>
                </div>

                <figure className="service-image__container">
                  <Img
                    className="service-image"
                    fluid={node.thumbnail.childImageSharp.fluid}
                    alt="hero warehouse"
                  />
                </figure>
              </div>

              <div className="grid services__grid">
                <div className="services__panels">
                  {node.offerings.map(offering => {
                    return (
                      <Panel key={`${node.title}-offering-${offering.title}`}>
                        {({ isOpen, toggleIsOpen }) => (
                          <div
                            className={`panel ${isOpen ? "open" : ""}`}
                            tabIndex="0"
                            onClick={toggleIsOpen}
                          >
                            <div className="panel__main">
                              <img
                                src={offering.icon.publicURL}
                                alt=""
                                className="panel__icon"
                              />
                              <h4 className="panel__title h4--bold">
                                {offering.title}
                              </h4>
                              <ChevronDownIcon className="panel__chevron" />
                            </div>

                            <div
                              className="panel__content"
                              dangerouslySetInnerHTML={{
                                __html: md.render(offering.description),
                              }}
                            ></div>
                          </div>
                        )}
                      </Panel>
                    )
                  })}
                </div>

                <div className="services__contact">
                  <div className="contact services__contact-cta">
                    <h4 className="h4--bold">
                      Contact us to learn more about our services
                    </h4>
                    <ContactForm></ContactForm>
                  </div>
                </div>
              </div>
            </section>

            {node.testimonial && (
              <Testimonial testimonialData={node.testimonial}></Testimonial>
            )}
          </React.Fragment>
        )
      })}

      <SoftwareCta />

      <ConsulationCta />
    </Layout>
  )
}

export const query = graphql`
  query {
    allServicesYaml(sort: { fields: title }) {
      edges {
        node {
          title
          description
          offerings {
            description
            title
            icon {
              publicURL
            }
          }
          icon {
            publicURL
          }
          # testimonial {
          #   resource
          #   quote_author_position
          #   quote_author
          #   quote
          # }
          thumbnail {
            childImageSharp {
              fluid(maxHeight: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    HeroWarehouse: file(relativePath: { eq: "hero-warehouse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Panel = ({ isOpen: initialOpenState = false, children }) => {
  const [isOpen, setIsOpen] = useState(initialOpenState)

  function toggleIsOpen() {
    setIsOpen(!isOpen)
  }

  return children({
    isOpen,
    setIsOpen,
    toggleIsOpen,
  })
}

export default Services
