import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import DemoLine from "../images/demo_line.inline.svg"

const SoftwareCta = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          image: file(relativePath: { eq: "screenshots/dashboard.png" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <article className="software-cta">
          <div className="container grid">
            <div className="software-cta__content">
              <Link
                to="/software"
                className="link link__title link--arrow-right software-cta__link"
              >
                Software
              </Link>

              <h1 className="software-cta__title">
                List items and manage your inventory with our powerful software.
              </h1>

              <Link to="/software" className="button button--yellow-light">
                See software
              </Link>
            </div>

            <DemoLine className="software-cta__line" />

            <Img
              fluid={data.image.childImageSharp.fluid}
              alt="Software"
              className="software-cta__img"
            />
          </div>
        </article>
      )}
    />
  )
}

export default SoftwareCta
